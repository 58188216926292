const pathToIgnore = [
  '/manageMarketplaces',
  '/onboarding/registry',
  '/onboarding/files',
  '/prospects',
  '/activeAuctionResults',
  '/allRoundDeadlines',
  '/auctions',
  '/auctions/simulations',
  '/suppliers/:supplierId/history',
  '/suppliers/all/histories',
  '/suppliers/withRiba'
]
export default function ignorePath (pathToCheck) {
  return pathToIgnore.includes(pathToCheck)
}
