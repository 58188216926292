import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Card, CardContent, CardHeader } from '@material-ui/core'

import Page from 'src/components/Page'
import Header from './Header'
import RegisterForm from './RegisterForm'
import { useSelector } from 'react-redux'
import CreateOperatorForm from './CreateOperatorForm'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(5)
  },
  cardHeader: {
    textTransform: 'uppercase'
  }
}))

/* Pagina di creazione nuovo utente per superAdmin, o creazione nuovo operatore per il commerciale di callcenter */
function CreateUserView () {
  const classes = useStyles()
  const session = useSelector(state => state.session)
  const { user } = session

  const { t } = useTranslation('createUserView')

  // Verifico se sono loggato come commerciale di callcenter
  const isCallcenter = user && user.role && user.role.length > 0 ? user.role[0].role.includes('CallCenterSalesperson') : false

  return (
    <Page
      className={classes.root}
      title={t('title')}
    >
      <Container maxWidth='xl'>
        <Header />
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} title={t('cardHeader')} />
          <CardContent>
            {isCallcenter ? <CreateOperatorForm /> : <RegisterForm />}
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default CreateUserView
