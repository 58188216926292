import configuration from 'src/configuration'

/* Url di base per le varie risorse utilizzate all'interno della dasboard */
export default {
  users: `${configuration.apiBaseUrl}/users`,
  companies: `${configuration.apiBaseUrl}/companies`,
  companiesData: `${configuration.apiBaseUrl}/companiesData`,
  headCompanies: `${configuration.apiBaseUrl}/headcompanies`,
  callcenters: `${configuration.apiBaseUrl}/callcenters`,
  clusters: `${configuration.apiBaseUrl}/clusters`,
  aclrules: `${configuration.apiBaseUrl}/aclRules`,
  invitations: `${configuration.apiBaseUrl}/invitations`,
  archivedAuctions: `${configuration.apiBaseUrl}/archivedAuctions`,
  invitesForBuyer: `${configuration.apiBaseUrl}/invitesForBuyer`,
  buyerOfSuppliers: `${configuration.apiBaseUrl}/buyerOfSuppliers`,
  stats: `${configuration.apiBaseUrl}/stats`,
  algorithm: `${configuration.apiBaseUrl}/algorithm`,
  aprWhitelist: `${configuration.apiBaseUrl}/apr-whitelist`,
  reports: `${configuration.apiBaseUrl}/reports`,
  opportunities: `${configuration.apiBaseUrl}/opportunities`,
  surveys: `${configuration.apiBaseUrl}/surveysAfterClosing`,
  activations: `${configuration.apiBaseUrl}/activations`,
  terms: `${configuration.apiBaseUrl}/terms`,
  auctionDeadlines: `${configuration.apiBaseUrl}/auction-deadlines`,
  suppliersToConfirmIban: `${configuration.apiBaseUrl}/suppliersToConfirmIban`,
  checkout: `${configuration.apiBaseUrl}/checkout`,
  trackBps: `${configuration.apiBaseUrl}/track-bps`
}
