import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import validate from 'validate.js'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, TextField, Box, Typography } from '@material-ui/core'
import Alert from 'src/components/Alert'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import api from 'src/utils/api'
import { useTranslation } from 'react-i18next'
import zxcvbn from 'zxcvbn'
import PasswordStrength from 'src/components/PasswordStrength'
import { createPassword } from 'src/utils/createRandomPassword'

const useStyles = makeStyles((theme) => ({
  root: {},
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  compositeGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  showAlert: {
    opacity: 0
  },
  caption: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}))

function CreateOperatorForm ({ className, ...rest }) {
  const classes = useStyles()
  const history = useHistory()
  const session = useSelector(state => state.session)
  const { currentCompany } = session

  const { t } = useTranslation('createOperatorForm')

  const schema = {
    username: {
      presence: { allowEmpty: false, message: t('schema.username') }
    },
    lastname: {
      presence: { allowEmpty: false, message: t('schema.lastname') }
    },
    email: {
      presence: { allowEmpty: false, message: t('schema.email.presence') },
      email: {
        message: t('schema.email.message')
      }
    },
    password: {
      presence: { allowEmpty: false, message: t('schema.password.presence') },
      length: {
        minimum: 8,
        maximum: 15,
        tooLong: t('schema.password.tooLong'),
        tooShort: t('schema.password.tooShort')
      },
      format: {
        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
        message: t('schema.password.format')
      }
    }
  }

  const [confirmEnabled, setConfirmEnabled] = useState(true)
  const [formState, setFormState] = useState({
    registerSuccess: false,
    registerError: false,
    registerMessage: '',
    isValid: false,
    passwordScore: 0,
    values: {},
    touched: {},
    errors: {}
  })

  const generatePassword = () => {
    const newPassword = createPassword()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        password: newPassword
      }
    }))
  }

  const handleChange = (event) => {
    event.persist()

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setConfirmEnabled(false)
    const { values } = formState
    try {
      const userBody = { email: values.email, fullName: `${values.username} ${values.lastname}`, password: values.password }
      // create user
      const user = await api.postResource('users', { body: userBody })
      const companyId = currentCompany.uuid
      // create acl
      const aclBody = {
        userId: user.uuid,
        companyId,
        role: 'CallCenterOperator'
      }
      await api.postResource('aclrules', { body: aclBody })
      // alert user created with success
      setFormState({ ...formState, registerError: false, registerSuccess: true, registerMessage: t('success') })
      setConfirmEnabled(true)
      history.push('/users')
    } catch (e) {
      setFormState({ ...formState, registerSuccess: false, registerError: true, registerMessage: t('error') })
      setConfirmEnabled(true)
    }
  }

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]))

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })
    const result = formState.values.password && zxcvbn(formState.values.password)
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      passwordScore: (result && result.score) || 0,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <>
      <Alert variant={formState.registerSuccess ? 'success' : 'default'} className={!formState.registerError && !formState.registerSuccess ? clsx(classes.alert, classes.showAlert) : classes.alert} message={formState.registerMessage} />
      <form
        {...rest}
        className={classes.root}
        onSubmit={handleSubmit}
      >
        <div className={classes.formGroup}>
          <TextField
            error={hasError('username')}
            helperText={hasError('username') ? formState.errors.username[0] : null}
            fullWidth
            label={t('fields.username')}
            name='username'
            onChange={(event) => handleChange(event)}
            value={formState.values.username || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            error={hasError('lastname')}
            helperText={hasError('lastname') ? formState.errors.lastname[0] : null}
            fullWidth
            label={t('fields.lastname')}
            name='lastname'
            onChange={(event) => handleChange(event)}
            value={formState.values.lastname || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <TextField
              error={hasError('password')}
              helperText={hasError('password') ? formState.errors.password[0] : null}
              fullWidth
              label={t('fields.password')}
              name='password'
              onChange={(event) => handleChange(event)}
              value={formState.values.password || ''}
              variant='outlined'
              InputProps={{
                endAdornment: (
                  <Box display='flex' alignItems='center'>
                    <Button onClick={(event) => generatePassword(event)} variant='outlined' style={{ minWidth: '200px' }} color='primary'>{t('fields.generateButton')}</Button>
                    <PasswordStrength
                      password={formState.values.password}
                      passwordScore={formState.passwordScore}
                    />
                  </Box>
                )
              }}
            />
            <Typography className={classes.caption} variant='subtitle2'>
              {t('fields.passwordMessage')}
            </Typography>
          </Box>
        </div>
        <div className={classes.formGroup}>
          <TextField
            error={hasError('email')}
            helperText={hasError('email') ? formState.errors.email[0] : null}
            fullWidth
            label={t('fields.email')}
            name='email'
            onChange={(event) => handleChange(event)}
            value={formState.values.email || ''}
            variant='outlined'
          />
        </div>
        <Button
          className={classes.submitButton}
          color='primary'
          disabled={!formState.isValid || !confirmEnabled}
          size='large'
          type='submit'
          variant='contained'
        >
          {t('buttons.create')}
        </Button>
      </form>
    </>)
}

CreateOperatorForm.propTypes = {
  className: PropTypes.string
}

export default CreateOperatorForm
