export const createPassword = () => {
  const length = 14
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const specialChars = '!#@*%'
  let retVal = ''
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  retVal += specialChars.charAt(Math.floor(Math.random() * 5))
  return retVal
}
