import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box, IconButton } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {},
  backButton: {
    marginRight: '24px'
  }
}))

function Header ({ className, ...rest }) {
  const classes = useStyles()
  const history = useHistory()

  const { t } = useTranslation('inviteUserView')

  const goBack = () => {
    history.goBack()
  }

  return (
    <Box display='flex'>
      <IconButton className={classes.backButton} color='primary' onClick={() => goBack()}>
        <KeyboardBackspaceIcon />
      </IconButton>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Typography
          component='h1'
          gutterBottom
          variant='h3'
        >
          {t('header.title')}
        </Typography>
        <Typography
          component='h2'
          variant='overline'
        >
          {t('header.subtitle')}
        </Typography>
      </div>
    </Box>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
