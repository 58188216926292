import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Popover,
  colors,
  ListItem,
  List
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import { languageToFlagMapping } from 'src/utils/flagUtils'

// Definizione degli stili per il componente
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%'
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  }
}))

/* Componente responsabile della visualizzazione del menu sulla TopBar */
function LanguagePopover ({ anchorEl, handleLogout, ...rest }) {
  const classes = useStyles()

  const { i18n } = useTranslation('profilePopover')
  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <List
          disablePadding
        >
          {
            Object.keys(i18n.services.resourceStore.data).map(k => (
              <ListItem
                key={k}
                className={classes.listItem}
                onClick={() => i18n.changeLanguage(k)}
              >
                <ReactCountryFlag svg countryCode={languageToFlagMapping(k)} />
              </ListItem>
            ))
          }
        </List>
      </div>
    </Popover>
  )
}

// Definizione delle proprietà accettate dal componente
LanguagePopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default LanguagePopover
