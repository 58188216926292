import api from './api'
/*
  Funzione che verifica l'esistenza di un'azienda.
  In caso di esistenza ritorna l'azienda esistente,
  altrimenti la crea e ritorna l'azienda appena creata
*/
export default async function getExistingCompany (vatCode, referentPhoneNumber, taxId, name, referentFullName, referentEmail) {
  const existentCompany = await api.getResource('companies', { params: { vatId: vatCode } })

  if (existentCompany.length <= 0) {
    // create company
    const companyBody = {
      name: name,
      status: 'inactive',
      vatId: vatCode,
      referentPhoneNumber,
      referentFullName,
      referentEmail,
      taxId,
      email: referentEmail
    }

    const company = await api.postResource('companies', { body: companyBody })
    return company
  } else {
    return existentCompany[0]
  }
}
