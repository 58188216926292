import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import axios from 'axios'
import ls from 'local-storage'
import configuration from './configuration'

axios.interceptors.response.use(response => response, error => {
  if (typeof error.response === 'undefined') {
    return Promise.reject(new Error('No response'))
  }

  if (error.response.status === 401 && error.response.data.error.code !== 'USER_AUTHENTICATION_ERROR_1') {
    ls.clear()
    return window.location.reload()
  }

  return Promise.reject(error)
})

const node = document.createElement('noscript')
node.innerHTML = `<iframe
  src='https://www.googletagmanager.com/ns.html?id=${configuration.gtmId}' height='0' width='0'
  style='display:none;visibility:hidden'
/>`

if (configuration.environment === 'production') {
  document.body.insertBefore(node, document.body.childNodes[0])
  /*  */
}

ReactDOM.render(<App />, document.getElementById('root'))
