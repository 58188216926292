/* eslint-disable no-undef */
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import configuration from 'src/configuration'

const { gtmId, environment } = configuration

function Page ({ title, children, ...rest }) {
  const location = useLocation()

  useEffect(() => {
    if (environment !== 'production') {
      return
    }

    if (window.gtag) {
      window.gtag('config', gtmId, {
        page_path: location.pathname,
        page_name: title
      })
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default Page
