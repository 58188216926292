import React, { Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'
import configuration from '../../configuration'
import GoogleAnalytics from '../../components/GoogleAnalytics'
import Clarity from '../../components/Clarity'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden'
  }
}))

/* Layout per le pagine di ricezione e conferma invito  */
function Invitation ({ route }) {
  const classes = useStyles()

  return (
    <>
      {configuration.environment === 'production' ? <GoogleAnalytics /> : null}
      {configuration.environment === 'production' ? <Clarity /> : null}
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  )
}

Invitation.propTypes = {
  route: PropTypes.object
}

export default Invitation
