/* eslint-disable react/no-danger */
import React from 'react'
import { Helmet } from 'react-helmet'

/* const gtmScript = `(function(w,d,s,l,i){w[l] = w[l] || []{'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NZ6DLML');` */

function Clarity () {
  return (
    <Helmet>
      <script>
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "avqxyv7et1");
    `}
      </script>
    </Helmet>
  )
}

export default Clarity
