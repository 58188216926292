import React, { Suspense, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { renderRoutes } from 'react-router-config'
import { LinearProgress, useMediaQuery } from '@material-ui/core'
import usePermissions from '../../utils/usePermissions'
import ls from 'local-storage'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden'
  },
  menuOpenContent: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  },
  menuClosedContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      transition: theme.transitions.create('padding', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}))

/* Layout per settare le discount preferences anche senza fare login */
function DiscountPreferencesLayout ({ route }) {
  const classes = useStyles()
  const user = ls.get('plsadv_user')
  const [openNavBarDesktop,] = useState(!!user)
  usePermissions()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <>
      <div className={classes.container}>
        <Suspense fallback={<LinearProgress />}>
          <div className={!isSmall && openNavBarDesktop ? clsx(classes.content, classes.menuOpenContent) : clsx(classes.content, classes.menuClosedContent)}>
            {renderRoutes(route.routes)}
          </div>
        </Suspense>
      </div>
    </>
  )
}

DiscountPreferencesLayout.propTypes = {
  route: PropTypes.object
}

export default DiscountPreferencesLayout
