import React from 'react'
import { Box, InputAdornment, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  weak: {
    color: theme.palette.primary.main
  },
  medium: {
    color: theme.palette.warning
  },
  strong: {
    color: theme.palette.success.main
  },
  passwordScore: {
    marginLeft: theme.spacing(1),
    fontSize: '11px'
  }
}))

function PasswordStrength ({ password, passwordScore }) {
  const classes = useStyles()
  const { t } = useTranslation('passwordStrength')

  const humanScore = {
    0: t('passwordScore.veryWeak'),
    1: t('passwordScore.weak'),
    2: t('passwordScore.medium'),
    3: t('passwordScore.strong'),
    4: t('passwordScore.veryStrong')
  }

  return (
    <Tooltip title={humanScore[passwordScore]}>
      <InputAdornment position='end'>
        {password ? [0, 1].includes(passwordScore) ? (
          <Box style={{ minWidth: '100px' }} display='flex' alignItems='center'>
            <CancelIcon className={classes.weak} />
            <span className={clsx(classes.passwordScore, classes.weak)}>
              {humanScore[passwordScore]}
            </span>
          </Box>
        ) : (
          <Box style={{ minWidth: '100px' }} display='flex' alignItems='center'>
            <CheckCircleIcon className={passwordScore === 2 ? classes.medium : classes.strong} />
            <span className={passwordScore === 2 ? clsx(classes.medium, classes.passwordScore) : clsx(classes.strong, classes.passwordScore)}>
              {humanScore[passwordScore]}
            </span>
          </Box>
        ) : ''}
      </InputAdornment>
    </Tooltip>
  )
}

export default PasswordStrength
