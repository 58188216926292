import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import validate from 'validate.js'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, TextField, Box, IconButton, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Alert from 'src/components/Alert'
import clsx from 'clsx'
import CompanyDialog from './CompanyDialog'
import { useRoles } from '../../utils/roles'
import api from 'src/utils/api'
import { useTranslation } from 'react-i18next'
import PasswordStrength from 'src/components/PasswordStrength'
import zxcvbn from 'zxcvbn'
import { createPassword } from 'src/utils/createRandomPassword'

const useStyles = makeStyles((theme) => ({
  root: {},
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  compositeGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  showAlert: {
    opacity: 0
  },
  caption: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}))

/* Componente per il form di creazione dell'utente */
function RegisterForm ({ className, ...rest }) {
  const classes = useStyles()
  const history = useHistory()

  const { roles, userLevelOptions, userRolesOptions, userTypeOptions } = useRoles()

  const { t } = useTranslation('registerForm')

  /* Schema di campi obbligatori presenti nella form */
  const schema = {
    email: {
      presence: { allowEmpty: false, message: t('schema.email.presence') },
      email: {
        message: t('schema.email.message')
      }
    },
    password: {
      presence: { allowEmpty: false, message: t('schema.password.presence') },
      length: {
        minimum: 8,
        maximum: 15,
        tooLong: t('schema.password.tooLong'),
        tooShort: t('schema.password.tooShort')
      },
      format: {
        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
        message: t('schema.password.format')
      }
    },
    userType: {
      presence: { allowEmpty: false, message: t('schema.userType.presence') }
    }
  }

  const [confirmEnabled, setConfirmEnabled] = useState(true)

  const [formState, setFormState] = useState({
    companyList: [],
    callcenterList: [],
    openModal: false,
    modalType: 'company',
    passwordScore: 0,
    registerSuccess: false,
    registerError: false,
    registerMessage: '',
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  // funzione che genera la password per l'utente che stiamo creando
  const generatePassword = () => {
    const newPassword = createPassword()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        password: newPassword
      }
    }))
  }

  const handleClose = () => {
    setFormState(prevFormState => ({
      ...prevFormState,
      openModal: false
    }))
  }

  const handleChange = (event) => {
    event.persist()

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  // funzione di submit del form
  const handleSubmit = async (event) => {
    event.preventDefault()
    setConfirmEnabled(false)
    const { values } = formState
    const role = values.userType === 'commercial' || values.userType === 'operator' ? roles[values.userType] : roles[values.userType] && roles[values.userType][values.userRole] && roles[values.userType][values.userRole][values.userLevel]
    // Se non sono settati ruolo e livello per un buyer o supplier blocca la creazione dell'utente
    if (!role && (values.userType === 'buyer' || values.userType === 'supplier') && (values.userRole || values.userRole === '')) {
      return setFormState({ ...formState, registerSuccess: false, registerError: true, registerMessage: t('error.userLevel') })
    }
    if (!role && (values.userType === 'buyer' || values.userType === 'supplier')) {
      return setFormState({ ...formState, registerSuccess: false, registerError: true, registerMessage: t('error.userRole') })
    }
    try {
      // Verifico se l'utente esiste già (controllo il campo email)
      const userExistsResponse = await api.getResource('users', { params: { email: values.email } })
      let user = {}

      // Se l'utente esiste ritorno quell'utente
      if (userExistsResponse.length > 0) {
        user = userExistsResponse[0]
      } else {
        // altrimenti ritorno l'utente appena creato
        const userBody = { email: values.email, password: values.password }
        // create user
        const userResponse = await api.postResource('users', { body: userBody })
        user = userResponse
      }

      const aclBody = {
        userId: user.uuid,
        companyId: values.company,
        role
      }
      // creo l' aclrule per il nuovo utente
      await api.postResource('aclrules', { body: aclBody })
      // Modify the company flag isBuyer if superAdmin is creating a buyerAdmin account
      // Se sto creando un utente buyerAdmin setto la company come company buyer
      if (role.includes('BuyerAdmin') && values.company !== '') {
        const updatedCompany = { isBuyer: true }
        await api.putResource('companies', { path: `/${values.company}`, body: updatedCompany })
      }

      // notifico che la creazione dell'utente è avvenuta avvenuta con successo
      setFormState({ ...formState, registerError: false, registerSuccess: true, registerMessage: t('success') })
      setConfirmEnabled(true)
      history.push('/users')
    } catch (e) {
      // Notifico che c'è stato un errore durante la creazione
      setFormState({ ...formState, registerSuccess: false, registerError: true, registerMessage: t('error.generic') })
      setConfirmEnabled(true)
    }
  }

  // funzione che prende e imposta nello stato locale i callcenters
  async function getCallcenters () {
    const callcenters = await api.getResource('callcenters')
    setFormState((prevFormState) => ({
      ...prevFormState,
      callcenterList: callcenters
    }))
  }

  // funzione che prende e imposta nello stato locale le companies
  async function getCompanies () {
    const companies = await api.getResource('companies')
    setFormState((prevFormState) => ({
      ...prevFormState,
      companyList: companies
    }))
  }

  // funzione che imposta il valore della company creata nella select delle companies
  const setCompany = async (companyUuid) => {
    await getCompanies()
    await getCallcenters()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        company: companyUuid
      },
      touched: {
        ...prevFormState.touched,
        company: true
      }
    }))
  }

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]))

  useEffect(() => {
    getCompanies()
    getCallcenters()
  }, [])

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })
    const result = formState.values.password && zxcvbn(formState.values.password)
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      passwordScore: (result && result.score) || 0,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <>
      <CompanyDialog type={formState.modalType} setCompany={setCompany} close={handleClose} open={formState.openModal} />
      <Alert variant={formState.registerSuccess ? 'success' : 'default'} className={!formState.registerError && !formState.registerSuccess ? clsx(classes.alert, classes.showAlert) : classes.alert} message={formState.registerMessage} />
      <form
        {...rest}
        className={classes.root}
        onSubmit={handleSubmit}
      >
        <div className={classes.formGroup}>
          <TextField
            error={hasError('email')}
            helperText={hasError('email') ? formState.errors.email[0] : null}
            fullWidth
            label={t('fields.email')}
            name='email'
            onChange={(event) => handleChange(event)}
            value={formState.values.email || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <TextField
              error={hasError('password')}
              helperText={hasError('password') ? formState.errors.password[0] : null}
              fullWidth
              label={t('fields.password')}
              name='password'
              onChange={(event) => handleChange(event)}
              value={formState.values.password || ''}
              variant='outlined'
              InputProps={{
                endAdornment: (
                  <Box display='flex' alignItems='center'>
                    <Button
                      onClick={(event) => generatePassword(event)}
                      variant='outlined'
                      style={{ minWidth: '200px' }}
                      color='primary'
                    >
                      {t('fields.generateButton')}
                    </Button>
                    <PasswordStrength
                      password={formState.values.password}
                      passwordScore={formState.passwordScore}
                    />
                  </Box>
                )
              }}
            />
            <Typography className={classes.caption} variant='subtitle2'>{t('fields.passwordMessage')}</Typography>
          </Box>
        </div>
        <div className={classes.formGroup}>
          <TextField
            className={classes.field}
            fullWidth
            label={t('fields.userType')}
            name='userType'
            onChange={(event) => handleChange(
              event
            )}
            select
            SelectProps={{ native: true }}
            value={formState.values.userType}
            variant='outlined'
          >
            <option
              value=''
            />
            {userTypeOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
        <div className={classes.formGroup}>
          <TextField
            className={classes.field}
            fullWidth
            label={t('fields.userRole')}
            name='userRole'
            onChange={(event) => handleChange(
              event
            )}
            select
            SelectProps={{ native: true }}
            value={formState.values.userRole}
            variant='outlined'
            disabled={!(formState.values.userType && userRolesOptions[formState.values.userType] && userRolesOptions[formState.values.userType].length > 0)}
          >
            <option
              value=''
            />
            {formState.values.userType && userRolesOptions[formState.values.userType].map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
        <div className={classes.formGroup}>
          <TextField
            className={classes.field}
            fullWidth
            label={t('fields.userLevel')}
            name='userLevel'
            onChange={(event) => handleChange(
              event
            )}
            select
            SelectProps={{ native: true }}
            value={formState.values.userLevel}
            variant='outlined'
            disabled={!(formState.values.userType && userLevelOptions[formState.values.userType] && userLevelOptions[formState.values.userType].length > 0)}
          >
            <option
              value=''
            />
            {formState.values.userType && userLevelOptions[formState.values.userType].map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
        <div className={clsx(classes.formGroup, classes.compositeGroup)}>
          <TextField
            InputLabelProps={{ shrink: !!formState.values.company }}
            className={classes.field}
            fullWidth
            label={formState.values.userType === 'commercial' || formState.values.userType === 'operator' ? t('fields.callCenter') : t('fields.company')}
            name='company'
            onChange={(event) => handleChange(
              event
            )}
            select
            disabled={!formState.values.userType}
            SelectProps={{ native: true }}
            value={formState.values.company}
            variant='outlined'
          >
            <option
              value=''
            />
            {formState.values.userType === 'commercial' || formState.values.userType === 'operator'
              ? formState.callcenterList.map((option) => (
                <option
                  key={option.uuid}
                  value={option.uuid}
                >
                  {option.name}
                </option>
              ))
              : formState.companyList.map((option) => (
                <option
                  key={option.uuid}
                  value={option.uuid}
                >
                  {option.name}
                </option>
              ))}
          </TextField>
          <Box m={1}>
            <Tooltip title={formState.values.userType === 'commercial' || formState.values.userType === 'operator' ? t('fields.callCenterTooltip') : t('fields.companyTooltip')}>
              <span>
                <IconButton
                  disabled={!formState.values.userType}
                  color='primary'
                  onClick={() => setFormState((prevFormState) => ({
                    ...prevFormState,
                    modalType: formState.values.userType === 'commercial' || formState.values.userType === 'operator' ? 'callcenter' : 'company',
                    openModal: true
                  }))}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </div>
        <Button
          className={classes.submitButton}
          color='primary'
          disabled={!formState.isValid || !confirmEnabled}
          size='large'
          type='submit'
          variant='contained'
        >
          {t('buttons.create')}
        </Button>
      </form>
    </>)
}

RegisterForm.propTypes = {
  className: PropTypes.string
}

export default RegisterForm
