import CryptoJS from 'crypto-js'

function calculateHash (string) {
  return CryptoJS.SHA256(string).toString()
}
// Funzione che verifica se un utente è superAdmin
function extractPartOfEmail (email) {
  const atIndex = email.indexOf('@')
  if (atIndex !== -1) {
    return email.slice(atIndex + 1)
  }
  return null // o un valore predefinito in caso di mancata corrispondenza
}
const hashed = 'd2010dea0663da2ffa5fbad4b2398794fea8b7111fecd640f05b25085f49d155'
export default function isUserASuperAdmin (email) {
  const part = extractPartOfEmail(email)
  return hashed === calculateHash(part)
}
