import * as actionTypes from 'src/actions'

const initialState = {
  marketplaces: [],
  currentAuction: {},
  auctionSuppliers: [],
  selectedSuppliers: [],
  proposedOffer: null
}

const marketplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROPOSED_OFFER: {
      return {
        ...state,
        proposedOffer: action.payload
      }
    }
    case actionTypes.CLEAR_MARKETPLACE: {
      return initialState
    }
    case actionTypes.SET_SELECTED_SUPPLIERS: {
      return {
        ...state,
        selectedSuppliers: action.payload
      }
    }
    case actionTypes.SET_AUCTION_SUPPLIERS: {
      return {
        ...state,
        auctionSuppliers: action.payload
      }
    }
    case actionTypes.SET_MARKETPLACES: {
      return {
        ...state,
        marketplaces: action.payload
      }
    }
    case actionTypes.SET_CURRENT_AUCTION: {
      return {
        ...state,
        currentAuction: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default marketplaceReducer
