import React, { Suspense, useState } from 'react'
import { renderRoutes } from 'react-router-config'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress, useMediaQuery } from '@material-ui/core'
import NavBar from './NavBar'
import TopBar from './TopBar'
import ls from 'local-storage'
import usePermissions from 'src/utils/usePermissions'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import configuration from '../../configuration'
import GoogleAnalytics from '../../components/GoogleAnalytics'
import Clarity from '../../components/Clarity'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden'
  },
  menuOpenContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '14.5vw',
      transition: theme.transitions.create('padding', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  },
  menuClosedContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      transition: theme.transitions.create('padding', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 56
    }
  }
}))

/* Layout per l'intera dashboard. Posso accederci solo se ho fatto login */
function Dashboard ({ route }) {
  const classes = useStyles()
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)
  const [openNavBarDesktop, setOpenNavBarDesktop] = useState(true)
  const history = useHistory()
  const requestedLocation = history.location.pathname

  // Verifico i permessi e setto utente e token nel localstorage
  usePermissions()
  const user = ls.get('plsadv_user')
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <>
      {configuration.environment === 'production' ? <GoogleAnalytics /> : null}
      {configuration.environment === 'production' ? <Clarity /> : null}
      <TopBar isNavBarDesktopOpen={openNavBarDesktop} onCloseNavBarDesktop={() => setOpenNavBarDesktop(false)} onOpenNavBarDesktop={() => setOpenNavBarDesktop(true)} onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        openDesktop={openNavBarDesktop}
      />
      <div className={classes.container}>
        <div className={!isSmall && openNavBarDesktop ? clsx(classes.content, classes.menuOpenContent) : clsx(classes.content, classes.menuClosedContent)}>
          <Suspense fallback={<LinearProgress />}>
            {user ? renderRoutes(route.routes) : <Redirect to={`/auth/login?requestedUrl=${requestedLocation}`} />}
          </Suspense>
        </div>
      </div>
    </>
  )
}

Dashboard.propTypes = {
  route: PropTypes.object
}

export default Dashboard
