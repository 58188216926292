import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Popover,
  CardHeader,
  Divider,
  Button,
  colors,
  ListItemText,
  ListItemAvatar,
  ListItem,
  List
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import ProfileIcon from '@material-ui/icons/PermIdentity'
import { useTranslation } from 'react-i18next'

// Definizione degli stili per il componente
const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    maxWidth: '100%'
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  }
}))

/* Componente responsabile della visualizzazione del menu sulla TopBar */
function ProfilePopover ({ anchorEl, handleLogout, ...rest }) {
  const classes = useStyles()
  const history = useHistory()

  const { t } = useTranslation('profilePopover')

  // funzione che rimanda alla pagina del profilo utente
  const goToProfile = (url = '/profile') => {
    history.push(url)
    rest.onClose()
  }

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <CardHeader title={t('menuTitle')} />
        <Divider />
        <List
          disablePadding
        >
          <ListItem
            className={classes.listItem}
            component={Button}
            color='inherit'
            onClick={() => goToProfile('/profile')}
          >
            <ListItemAvatar>
              <ProfileIcon />
            </ListItemAvatar>
            <ListItemText
              primary={t('profileTitle')}
            />
          </ListItem>
          <Divider />
          <ListItem
            className={classes.listItem}
            component={Button}
            color='inherit'
            onClick={handleLogout}
          >
            <ListItemAvatar>
              <InputIcon />
            </ListItemAvatar>
            <ListItemText
              primary={t('exitTitle')}
            />
          </ListItem>
        </List>
      </div>
    </Popover>
  )
}

// Definizione delle proprietà accettate dal componente
ProfilePopover.propTypes = {
  anchorEl: PropTypes.any,
  handleLogout: PropTypes.func.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default ProfilePopover
