import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Backdrop, CircularProgress, Box, Card, Typography, CardContent } from '@material-ui/core'
import Page from 'src/components/Page'

import ResetPasswordForm from './ResetPasswordForm'
import { red } from '@material-ui/core/colors'
import { useParams, useHistory } from 'react-router'
import api from 'src/utils/api'
import Message from 'src/components/Message'
import { useTranslation } from 'react-i18next'
import ChangeLanguageButton from '../../components/ChangeLanguageButton'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(6, 2)
  },
  card: {
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.sm
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  cardHeader: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardHeaderTitle: {
    color: theme.palette.common.white,
    textTransform: 'uppercase'
  },
  cardHeaderText: {
    color: red[100],
    transition: 'color 0.2s',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.white,
      transition: 'color 0.2s'
    }
  },
  content: {
    padding: theme.spacing(2, 4, 3, 4),
    textAlign: 'center'
  },
  backdrop: {
    zIndex: 900
  },
  icon: {
    height: 'auto',
    width: '50%'
  }
}))

function ResetPasswordView () {
  const params = useParams()
  const classes = useStyles()
  const history = useHistory()

  const { t } = useTranslation('resetPasswordView')

  const { resetToken: token, email } = params

  const [loaderStatus, setLoaderStatus] = useState(false)
  const [hasResponse, setHasResponse] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const [isReady, setIsReady] = useState(false)

  const resetPassword = async (values) => {
    setLoaderStatus(true)
    const { password, confirmPassword } = values
    if (password && confirmPassword && token && email) {
      try {
        const body = { newPassword: password, email, token }
        await api.postResource('users', { path: '/resetPassword', body })
        setHasResponse('success')
        setResponseMessage(t('notifications.success'))
      } catch (e) {
        const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.statusCode
        setHasResponse('error')
        if (errCode === 404) {
          setResponseMessage(t('notifications.invalidLink'))
        } else {
          setResponseMessage(t('notifications.error'))
        }
      }
    }
    setLoaderStatus(false)
  }

  useState(() => {
    async function verifyToken () {
      try {
        await api.getResource('users', { path: `/resetPassword/validate?email=${email}&token=${token}` })
        setIsReady(true)
      } catch (e) {
        const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.statusCode
        setHasResponse('error')
        if (errCode === 404) {
          setResponseMessage(t('notifications.invalidLink'))
        } else {
          setResponseMessage(t('notifications.error'))
        }
        setIsReady(true)
      }
    }

    verifyToken()
  }, [email, token])

  if (!isReady) {
    return null
  }

  return (
    <Page
      className={classes.root}
      title={t('title')}
    >
      <Backdrop className={classes.backdrop} open={loaderStatus}>
        <CircularProgress color='primary' />
      </Backdrop>
      <Card className={classes.card}>
        <Box className={classes.cardHeader}>
          <Typography className={classes.cardHeaderTitle}>{t('headerTitle')}</Typography>
          <ChangeLanguageButton />
        </Box>
        <CardContent className={classes.content}>
          <img className={classes.icon} src='/images/logo.png' alt='plusadvance' />
          {hasResponse && hasResponse === 'success' ? <Message buttonText={t('buttons.login')} action={() => history.push('/auth/login')}>{responseMessage}</Message> : hasResponse && hasResponse === 'error' ? <Message>{responseMessage}</Message> : <ResetPasswordForm action={resetPassword} />}
        </CardContent>
      </Card>
      <Box mt={8}>
        <Typography variant='subtitle2'>{t('copyright')}</Typography>
      </Box>
    </Page>)
}

export default ResetPasswordView
