export const SESSION_LOGIN = 'SESSION_LOGIN'
export const SESSION_AUTH = 'SESSION_AUTH'
export const SESSION_LOGOUT = 'SESSION_LOGOUT'
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY'
export const SET_CURRENT_HEAD_COMPANY = 'SET_CURRENT_HEAD_COMPANY'
export const SET_ALL_COMPANIES = 'SET_ALL_COMPANIES'
export const SET_ALL_HEAD_COMPANIES = 'SET_ALL_HEAD_COMPANIES'
export const SET_ALL_CALLCENTERS = 'SET_ALL_CALLCENTERS'
export const SET_OFFER_STATUS = 'SET_OFFER_STATUS'
export const ACTIVATE_MARKETPLACE_TOUR = 'ACTIVATE_MARKETPLACE_TOUR'
export const DEACTIVATE_MARKETPLACE_TOUR = 'DEACTIVATE_MARKETPLACE_TOUR'
export const ACTIVATE_MARKETPLACE_DETAILS_TOUR = 'ACTIVATE_MARKETPLACE_DETAILS_TOUR'
export const DEACTIVATE_MARKETPLACE_DETAILS_TOUR = 'DEACTIVATE_MARKETPLACE_DETAILS_TOUR'

export const setOfferStatus = (status) => (dispatch) => dispatch({
  type: SET_OFFER_STATUS,
  payload: status
})

export const auth = (user) => (dispatch) => dispatch({
  type: SESSION_AUTH,
  payload: user
})

export const setCompany = (company) => (dispatch) => dispatch({
  type: SET_CURRENT_COMPANY,
  payload: company
})

export const setHeadCompany = (headCompany) => (dispatch) => dispatch({
  type: SET_CURRENT_HEAD_COMPANY,
  payload: headCompany
})

export const setCompanies = (companies) => (dispatch) => dispatch({
  type: SET_ALL_COMPANIES,
  payload: companies
})

export const setHeadCompanies = (headCompanies) => (dispatch) => dispatch({
  type: SET_ALL_HEAD_COMPANIES,
  payload: headCompanies
})

export const setCallcenters = (callcenters) => (dispatch) => dispatch({
  type: SET_ALL_CALLCENTERS,
  payload: callcenters
})

export const login = (user) => (dispatch) => dispatch({
  type: SESSION_LOGIN,
  payload: user
})

export const logout = () => (dispatch) => dispatch({
  type: SESSION_LOGOUT
})

export const setDetailsTourDone = (tourType) => (dispatch) => dispatch({
  type: ACTIVATE_MARKETPLACE_DETAILS_TOUR,
  payload: tourType
})

export const setMarketplaceTourDone = () => (dispatch) => dispatch({
  type: ACTIVATE_MARKETPLACE_TOUR
})

export const resetDetailsTour = tourType => dispatch => dispatch({
  type: DEACTIVATE_MARKETPLACE_DETAILS_TOUR,
  payload: tourType
})

export const resetMarketplaceTour = () => dispatch => dispatch({
  type: DEACTIVATE_MARKETPLACE_TOUR
})
