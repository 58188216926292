import * as actionTypes from 'src/actions'

const initialState = {
  loggedIn: false,
  currentCompany: {
    name: '',
    status: 'inactive',
    vatId: '',
    taxId: '',
    email: '',
    pec: ''
  },
  currentHeadCompany: {
    name: ''
  },
  companies: [],
  headCompanies: [],
  callcenters: [],
  offerStatus: '',
  user: {
    language: 'it',
    fullName: '',
    jobTitle: '',
    email: '',
    marketplaceTourDone: true,
    marketplaceDetailsTourDone: {
      auctionClosed: true,
      supplierOff: false,
      supplierOnNoInvoices: false,
      supplierOnNotConfirmed: false
    },
    role: []
  }
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OFFER_STATUS: {
      return {
        ...state,
        offerStatus: action.payload
      }
    }
    case actionTypes.SESSION_AUTH: {
      return {
        ...state,
        user: action.payload
      }
    }
    case actionTypes.SET_ALL_COMPANIES: {
      return {
        ...state,
        companies: action.payload
      }
    }
    case actionTypes.SET_ALL_HEAD_COMPANIES: {
      return {
        ...state,
        headCompanies: action.payload
      }
    }
    case actionTypes.SET_ALL_CALLCENTERS: {
      return {
        ...state,
        callcenters: action.payload
      }
    }
    case actionTypes.SET_CURRENT_COMPANY: {
      return {
        ...state,
        currentCompany: action.payload
      }
    }
    case actionTypes.SET_CURRENT_HEAD_COMPANY: {
      return {
        ...state,
        currentHeadCompany: action.payload
      }
    }
    case actionTypes.ACTIVATE_MARKETPLACE_TOUR: {
      return {
        ...state,
        user: {
          ...state.user,
          marketplaceTourDone: false
        }
      }
    }
    case actionTypes.DEACTIVATE_MARKETPLACE_TOUR: {
      return {
        ...state,
        user: {
          ...state.user,
          marketplaceTourDone: true
        }
      }
    }
    case actionTypes.ACTIVATE_MARKETPLACE_DETAILS_TOUR: {
      return {
        ...state,
        user: {
          ...state.user,
          marketplaceDetailsTourDone: {
            ...state.user.marketplaceDetailsTourDone,
            [action.payload]: false
          }
        }
      }
    }
    case actionTypes.DEACTIVATE_MARKETPLACE_DETAILS_TOUR: {
      return {
        ...state,
        user: {
          ...state.user,
          marketplaceDetailsTourDone: {
            ...state.user.marketplaceDetailsTourDone,
            [action.payload]: true
          }
        }
      }
    }
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
        user: action.payload
      }
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...initialState
      }
    }

    default: {
      return state
    }
  }
}

export default sessionReducer
