/* eslint-disable react/no-danger */
import React from 'react'
import { Helmet } from 'react-helmet'
import configuration from 'src/configuration'

/* const gtmScript = `(function(w,d,s,l,i){w[l] = w[l] || []{'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NZ6DLML');` */

function FreshChat () {
  return (
    <Helmet>
      <script
        async
        src='https://wchat.freshchat.com/js/widget.js'
      />
      <script>
        {` 
        function initFreshChat() { 
        window.fcWidget.init({ 
          token: "${configuration.freshChatKey}", 
          host: "https://wchat.eu.freshchat.com",
          locale: "it",
          faqTags: {
          tags: ['general'],
          filterType: 'category'
          }
        }); 
       } 
        function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.eu.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"freshchat-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1); 
       `}
      </script>
    </Helmet>
  )
}

export default FreshChat
