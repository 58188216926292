import React, { useState, useEffect } from 'react'
import validate from 'validate.js'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, TextField, Box } from '@material-ui/core'
import Alert from 'src/components/Alert'
import clsx from 'clsx'
import getExistingCompany from 'src/utils/getExistingCompany'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {},
  formGroup: {
    marginBottom: theme.spacing(2)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  showAlert: {
    opacity: 0
  }
}))

/* Componente del Form di creazione di una company  */
function RegisterCompanyForm ({ className, close, setCompanyAndClose, ...rest }) {
  const classes = useStyles()
  const { t } = useTranslation('registerCompanyForm')

  const schema = {
    name: {
      presence: { allowEmpty: false, message: t('schema.name') }
    },
    email: {
      presence: { allowEmpty: false, message: t('schema.email.presence') },
      email: {
        message: t('schema.email.message')
      }
    },
    username: {
      presence: { allowEmpty: false, message: t('schema.username') }
    },
    phoneNumber: {
      presence: { allowEmpty: false, message: t('schema.phone') }
    },
    vatCode: {
      presence: { allowEmpty: false, message: t('schema.vatCode.presence') },
      length: { is: 11, wrongLength: t('schema.vatCode.length') }
    },
    taxId: {
      presence: { allowEmpty: false, message: t('schema.taxId.presence') },
      length: function (value) {
        if (value) {
          if (value.length > 11) return { is: 16, wrongLength: t('schema.taxId.length') }

          return { is: 11, wrongLength: t('schema.taxId.valid') }
        }
        return false
      }
    }
  }

  const [confirmEnabled, setConfirmEnabled] = useState(true)

  const [formState, setFormState] = useState({
    registerSuccess: false,
    registerError: false,
    registerMessage: '',
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  const handleChange = (event) => {
    event.persist()

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  // funzione di submit della form
  const handleSubmit = async (event) => {
    setConfirmEnabled(false)
    event.preventDefault()
    const { values } = formState
    const { name, email: referentEmail, vatCode, taxId, phoneNumber: referentPhoneNumber, username: referentFullName } = values
    try {
      const company = await getExistingCompany(vatCode, referentPhoneNumber, taxId, name, referentFullName, referentEmail)
      setCompanyAndClose(company.uuid)
      setConfirmEnabled(true)
    } catch (e) {
      console.log(e)
      setConfirmEnabled(true)
    }
  }

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]))

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <>
      <Alert variant={formState.registerSuccess ? 'success' : 'default'} className={!formState.registerError && !formState.registerSuccess ? clsx(classes.alert, classes.showAlert) : classes.alert} message={formState.registerMessage} />
      <form
        {...rest}
        className={classes.root}
        onSubmit={handleSubmit}
      >
        <div className={classes.formGroup}>
          <TextField
            error={hasError('name')}
            helperText={hasError('name') ? formState.errors.name[0] : null}
            fullWidth
            required
            label={t('fields.name')}
            name='name'
            onChange={(event) => handleChange(event)}
            values={formState.values.name || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            error={hasError('vatCode')}
            helperText={hasError('vatCode') ? formState.errors.vatCode[0] : null}
            fullWidth
            required
            type='number'
            label={t('fields.vatCode')}
            name='vatCode'
            onChange={(event) => handleChange(event)}
            values={formState.values.vatCode || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            error={hasError('taxId')}
            helperText={hasError('taxId') ? formState.errors.taxId[0] : null}
            fullWidth
            required
            label={t('fields.taxId')}
            name='taxId'
            onChange={(event) => handleChange(event)}
            values={formState.values.taxId || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            error={hasError('username')}
            helperText={hasError('username') ? formState.errors.username[0] : null}
            fullWidth
            required
            label={t('fields.userName')}
            name='username'
            onChange={(event) => handleChange(event)}
            value={formState.values.username || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            fullWidth
            required
            label={t('fields.phoneNumber')}
            name='phoneNumber'
            onChange={(event) => handleChange(event)}
            values={formState.values.phoneNumber || ''}
            variant='outlined'
          />
        </div>
        <div className={classes.formGroup}>
          <TextField
            error={hasError('email')}
            helperText={hasError('email') ? formState.errors.email[0] : null}
            fullWidth
            required
            label={t('fields.email')}
            name='email'
            onChange={(event) => handleChange(event)}
            values={formState.values.email || ''}
            variant='outlined'
          />
        </div>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <Button
            onClick={close}
            className={classes.button}
            color='default'
            size='large'
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className={classes.button}
            color='primary'
            disabled={!formState.isValid || !confirmEnabled}
            size='large'
            type='submit'
            variant='contained'
          >
            {t('buttons.create')}
          </Button>
        </Box>
      </form>
    </>)
}

RegisterCompanyForm.propTypes = {
  className: PropTypes.string,
  close: PropTypes.func.isRequired
}

export default RegisterCompanyForm
