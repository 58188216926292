import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  contentText: {
    marginBottom: theme.spacing(8)
  }
}))

// Componente che fornisce una formattazione omogenea per i messaggi da visualizzare a schermo per l'utente
function Message ({ children, action, form, buttonText, ...rest }) {
  const classes = useStyles()

  const [confirmEnabled, setConfirmEnabled] = useState(true)

  return (
    <>
      <Typography component='div' className={action && buttonText && classes.contentText} variant='subtitle2'>
        {children}
      </Typography>
      {form && form}
      {!form && action && buttonText && (
        <Button
          disabled={!confirmEnabled}
          onClick={() => {
            setConfirmEnabled(false)
            action()
            setConfirmEnabled(true)
          }}
          variant='contained'
          color='primary'
        >
          {buttonText}
        </Button>)}
    </>
  )
}

Message.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  form: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  buttonText: PropTypes.string,
  action: PropTypes.func
}

export default Message
