import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { Typography, Box, IconButton } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {},
  backButton: {
    marginRight: '24px'
  }
}))

function Header ({ className, ...rest }) {
  const classes = useStyles()
  const history = useHistory()
  const session = useSelector(state => state.session)
  const { user } = session

  const { t } = useTranslation('createUserView')

  const isCallcenter = user && user.role && user.role.length > 0 ? user.role[0].role.includes('CallCenterSalesperson') : false

  const goBack = () => {
    history.goBack()
  }

  return (
    <Box display='flex'>
      <IconButton onClick={() => goBack()} className={classes.backButton} color='primary'>
        <KeyboardBackspaceIcon />
      </IconButton>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Typography
          component='h1'
          gutterBottom
          variant='h3'
        >
          {isCallcenter ? t('header.operatorTitle') : t('header.userTitle')}
        </Typography>
        <Typography
          component='h2'
          variant='overline'
        >
          {isCallcenter ? t('header.operatorSubtitle') : t('header.userSubtitle')}
        </Typography>
      </div>
    </Box>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
