import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import { TextField, Box, Button, InputAdornment } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import zxcvbn from 'zxcvbn'
import PasswordStrength from 'src/components/PasswordStrength'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {},
  fields: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  }
}))
const useHelperTextStyles = makeStyles(() => ({
  root: {
    paddingTop: 8
  }
}))
function ResetPasswordForm ({ action }) {
  const classes = useStyles()

  const { t } = useTranslation('resetPasswordForm')

  const [showPassword, setShowPassword] = useState(false)

  const [confirmEnabled, setConfirmEnabled] = useState(true)
  const [formState, setFormState] = useState({
    isValid: false,
    passwordScore: 0,
    values: {},
    touched: {},
    errors: {}
  })
  const helperTextStyles = useHelperTextStyles()

  const getPasswordError = () => {
    const password = formState.values.password
    if (formState && formState.values && formState.values.password) {
      const lowerCaseLetters = /[a-z]/g
      if (!password.match(lowerCaseLetters)) {
        return t('schema.password.minusc')
      }
      const upperCaseLetters = /[A-Z]/g
      if (!password.match(upperCaseLetters)) {
        return t('schema.password.maiusc')
      }
      const numbers = /[0-9]/g
      if (!password.match(numbers)) {
        return t('schema.password.numbers')
      }
      const notWordChars = /[^a-zA-Z0-9]/g
      if (!password.match(notWordChars)) {
        return t('schema.password.symbols')
      }
      return t('schema.password.notValid')
    } else {
      return t('schema.password.format')
    }
  }
  const schema = {
    password: {
      presence: { allowEmpty: false, message: t('schema.password.presence') },
      length: {
        minimum: 8,
        maximum: 20,
        tooLong: t('schema.password.tooLong'),
        tooShort: t('schema.password.tooShort')
      },
      format: {
        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
        message: getPasswordError()
      }
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: t('schema.confirmPassword.presence') },
      equality: {
        attribute: 'password',
        message: t('schema.confirmPassword.equality')
      }
    }
  }
  const handleChange = (event) => {
    event.persist()

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setConfirmEnabled(false)
    const { values } = formState
    action(values)
    setConfirmEnabled(true)
  }

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field])

  useEffect(() => {
    let errors = validate(formState.values, schema, { fullMessages: false })
    const result = formState.values.password && zxcvbn(formState.values.password)
    if ((result && result.score < 2)) {
      if (!errors) {
        errors = {}
      }
      if (!('password' in errors)) {
        errors.password = []
      }
      errors.password.push(t('schema.password.weakPassword'))
    }
    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors && (result && result.score > 1),
      passwordScore: (result && result.score) || 0,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <TextField
          error={hasError('password')}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          helperText={hasError('password') ? formState.errors.password[0] : null}
          label={t('fields.password')}
          name='password'
          onChange={handleChange}
          value={formState.values.password || ''}
          variant='outlined'
          FormHelperTextProps={{
            classes: {
              root: helperTextStyles.root
            }
          }}
          InputProps={{
            endAdornment: (
              <PasswordStrength
                password={formState.values.password}
                passwordScore={formState.passwordScore}
              />
            ),
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          error={hasError('confirmPassword')}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          helperText={hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null}
          label={t('fields.confirmPassword')}
          required
          name='confirmPassword'
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          value={formState.values.confirmPassword || ''}
          variant='outlined'
        />
      </div>
      <Box display='flex' alignItems='center'>
        <Button
          className={classes.submitButton}
          color='primary'
          disabled={!formState.isValid || !confirmEnabled}
          size='large'
          type='submit'
          variant='contained'
        >
          {t('buttons.setPassword')}
        </Button>
      </Box>
    </form>
  )
}

ResetPasswordForm.propTypes = {
  action: PropTypes.func.isRequired
}

export default ResetPasswordForm
