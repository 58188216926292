import { useTranslation } from 'react-i18next'

export const useRoles = () => {
  const { t } = useTranslation('roles')
  // Tipi di utente
  const userTypeOptions = [{
    label: t('buyer'),
    value: 'buyer'
  },
  {
    label: t('supplier'),
    value: 'supplier'
  },
  {
    label: t('commercial'),
    value: 'commercial'
  },
  {
    label: t('funderPartner'),
    value: 'funderPartner'
  },
  {
    label: t('operator'),
    value: 'operator'
  }]
  // Livelli di un utente
  const userLevelOptions = {
    operator: [],
    commercial: [],
    funderPartner:[],
    orderToCashAdmin: [],
    creditorAdmin: [],
    buyer: [{
      label: t('full'),
      value: 'full'
    },
    {
      label: t('readonly'),
      value: 'readonly'
    }],
    supplier: [{
      label: t('full'),
      value: 'full'
    },
    {
      label: t('readonly'),
      value: 'readonly'
    }]
  }
  // Ruoli di un utente
  const userRolesOptions = {
    operator: [],
    commercial: [],
    funderPartner: [
      { label: t('admin'), value: 'admin' }
    ],
    creditorAdmin: [
      { label: t('admin'), value: 'admin' }
    ],
    orderToCashAdmin: [
      { label: t('admin'), value: 'admin' }
    ],
    superAdmin: [
      { label: t('admin'), value: 'admin' },
      { label: t('manager'), value: 'manager' },
      { label: t('operatorFull'), value: 'operatorFull' },
      { label: t('operatorRead'), value: 'operatorReadOnly' }
    ],
    admin: [
      { label: t('manager'), value: 'manager' },
      { label: t('operatorFull'), value: 'operatorFull' },
      { label: t('operatorRead'), value: 'operatorReadOnly' }
    ],
    manager: [
      { label: t('operatorFull'), value: 'operatorFull' },
      { label: t('operatorRead'), value: 'operatorReadOnly' }
    ],
    supplier: [{
      label: t('admin'),
      value: 'admin'
    },
    {
      label: t('financial'),
      value: 'financial'
    },
    {
      label: t('technical'),
      value: 'technical'
    }],
    buyer: [{
      label: t('admin'),
      value: 'admin'
    },
    {
      label: t('financial'),
      value: 'financial'
    },
    {
      label: t('technical'),
      value: 'technical'
    }]
  }
  // oggetto che contiene le stringhe di ruoli da inviare in una chiamata HTTP
  const roles = {
    commercial: 'CallCenterSalesperson',
    operator: 'CallCenterOperator',
    buyer: {
      admin: {
        full: 'BuyerAdminFull',
        readonly: 'BuyerAdminReadOnly'
      },
      financial: {
        full: 'BuyerFinancialFull',
        readonly: 'BuyerFinancialReadOnly'
      },
      technical: {
        full: 'BuyerTechnicalFull',
        readonly: 'BuyerTechnicalReadOnly'
      }
    },
    supplier: {
      admin: {
        full: 'SupplierAdminFull',
        readonly: 'SupplierAdminReadOnly'
      },
      financial: {
        full: 'SupplierFinancialFull',
        readonly: 'SupplierFinancialReadOnly'
      },
      technical: {
        full: 'SupplierTechnicalFull',
        readonly: 'SupplierTechnicalReadOnly'
      }
    }
  }

  // Funzione che ritorna un ruolo leggibile partendo dal ruolo codificato
  const getRoles = (role) => {
    const roles = {
      FunderPartner: t('funderPartner'),
      OrderToCashAdmin: t('OrderToCashAdmin'),
      BuyerAdmin: t('buyerAdmin'),
      BuyerManager: t('buyerManager'),
      BuyerOperatorFull: t('buyerOperatorFull'),
      BuyerOperatorReadOnly: t('buyerOperatorReadOnly'),
      SupplierAdmin: t('supplierAdmin'),
      SupplierManager: t('supplierManager'),
      SupplierOperatorFull: t('supplierOperatorFull'),
      SupplierOperatorReadOnly: t('supplierOperatorReadOnly'),
      CallCenterSalesperson: t('callCenterSalesperson'),
      CallCenterOperator: t('callCenterOperator'),
      BuyerAdminFull: t('buyerAdminFull'),
      BuyerAdminReadOnly: t('buyerAdminReadOnly'),
      BuyerFinancialFull: t('buyerFinancialFull'),
      BuyerFinancialReadOnly: t('buyerFinancialReadOnly'),
      BuyerTechnicalFull: t('buyerTechnicalFull'),
      BuyerTechnicalReadOnly: t('buyerTechnicalReadOnly'),
      SupplierAdminFull: t('supplierAdminFull'),
      SupplierAdminReadOnly: t('supplierAdminReadOnly'),
      SupplierFinancialFull: t('supplierFinancialFull'),
      SupplierFinancialReadOnly: t('supplierFinancialReadOnly'),
      SupplierTechnicalFull: t('supplierTechnicalFull'),
      SupplierTechnicalReadOnly: t('supplierTechnicalReadOnly')
    }

    return roles[role]
  }

  return {
    userTypeOptions,
    getRoles,
    roles,
    userRolesOptions,
    userLevelOptions
  }
}
