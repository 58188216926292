import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Alert from 'src/components/Alert'
import { makeStyles } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
import validate from 'validate.js'
import { TextField, Button, Box, Tooltip, IconButton } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useRoles } from 'src/utils/roles'
import clsx from 'clsx'
import isUserASuperAdmin from 'src/utils/isSuperAdmin'
import CompanyDialog from '../CreateUserView/CompanyDialog'
import { useHistory } from 'react-router'
import api from 'src/utils/api'
import { useTranslation } from 'react-i18next'
import FormControl from '@material-ui/core/FormControl'
import { Autocomplete } from '@material-ui/lab'
import isPaOperator from 'src/utils/isPaOperator'

const useStyles = makeStyles((theme) => ({
  root: {},
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  showAlert: {
    opacity: 0
  },
  compositeGroup: {
    display: 'flex',
    alignItems: 'center'
  }
}))
function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function InviteForm ({ className, ...rest }) {
  const classes = useStyles()
  const session = useSelector(state => state.session)
  const history = useHistory()
  const { user, currentCompany } = session

  const { userRolesOptions } = useRoles()

  const { t } = useTranslation('inviteForm')

  const schema = {
    email: {
      presence: { allowEmpty: false, message: t('schema.email.presence') },
      email: {
        message: t('schema.email.message')
      }
    },
    userRole: {
      presence: { allowEmpty: false, message: t('schema.userType') }
    }
  }

  const isBuyerOrSupplier = () => {
    const { role: roles } = user
    const companyId = currentCompany.uuid
    const currentPermission = roles
      .filter(singleRole => singleRole.companyId === companyId)
      .filter(singleRole => singleRole.role.includes('Admin'))

    const permission = currentPermission.length > 0 ? currentPermission[0] : null
    console.log(roles)
    return permission && permission.role.includes('Supplier') ? 'supplier' : 'buyer'
  }
  // console.log(isBuyerOrSupplier(), 'b-s')
  const getUserType = () => {
    const { role: roles } = user
    const companyId = currentCompany.uuid
    const currentPermission = roles
      .filter(singleRole => singleRole.companyId === companyId)
    const permission = currentPermission.length > 0 ? currentPermission[0] : null
    if (permission && permission.role.includes('Admin')) {
      return 'admin'
    }
    if (permission && permission.role.includes('Manager')) {
      return 'manager'
    }
    return 'manager'
  }
  // console.log(getUserType(), 'usertype')
  const isSa = isUserASuperAdmin(user.email)
  const isAPaOperator = isPaOperator(user.email)
  const adminUserTypeOptions = [
    {
      label: t('userTypeOptions.buyer'),
      value: 'buyer'
    },
    {
      label: t('userTypeOptions.supplier'),
      value: 'supplier'
    },
    {
      label: t('userTypeOptions.funderPartner'),
      value: 'funderPartner'
    },
    {
      label: t('userTypeOptions.orderToCashAdmin'),
      value: 'orderToCash'
    },
    {
      label: t('userTypeOptions.creditorAdmin'),
      value: 'creditor'
    }
  ]
  const paOperatorUserTypeOption = [
    {
      label: t('userTypeOptions.supplier'),
      value: 'supplier'
    }
  ]
  // const userRolesOptions = {
  //   supplier: [
  //     {
  //       label: t('userTypeOptions.financial'),
  //       value: 'financial'
  //     },
  //     {
  //       label: t('userTypeOptions.technical'),
  //       value: 'technical'
  //     }
  //   ],
  //   buyer: [
  //     {
  //       label: t('userTypeOptions.financial'),
  //       value: 'financial'
  //     },
  //     {
  //       label: t('userTypeOptions.technical'),
  //       value: 'technical'
  //     }
  //   ]
  // }

  const adminUserRolesOptions = {
    supplier: [
      {
        label: t('userTypeOptions.admin'),
        value: 'admin'
      },
      ...userRolesOptions.supplier
    ],
    buyer: [
      {
        label: t('userTypeOptions.admin'),
        value: 'admin'
      },
      ...userRolesOptions.buyer
    ],
    funderPartner: [
      ...userRolesOptions.funderPartner
    ],
    creditor: [
      ...userRolesOptions.creditorAdmin
    ],
    orderToCash: [
      ...userRolesOptions.orderToCashAdmin
    ]
  }

  const [confirmEnabled, setConfirmEnabled] = useState(true)
  const [formState, setFormState] = useState({
    currentCompany,
    companyList: [],
    openModal: false,
    inviteError: false,
    inviteSuccess: false,
    inviteMessage: '',
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  const handleClose = () => {
    setFormState(prevFormState => ({
      ...prevFormState,
      openModal: false
    }))
  }

  async function getCompanies () {
    const { count: fetchCompaniesNum } = await api.getResource('companies', { path: '/count', params: { status: 'active' } })
    const companies = await api.getResource('companies', { params: { skip: 0, limit: fetchCompaniesNum, status: 'active', fields: ['name', 'email', 'phoneNumber', 'vatId', 'status', 'uuid'].join(',') } })
    // console.log('Num companies', companies.length)
    setFormState((prevFormState) => ({
      ...prevFormState,
      companyList: companies
    }))
  }

  const setCompany = async (companyUuid) => {
    await getCompanies()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        company: companyUuid
      },
      touched: {
        ...prevFormState.touched,
        company: true
      }
    }))
  }

  const handleChange = (event) => {
    event.persist()

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }
  const handleCompanySelection = (newValue) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        company: newValue.uuid
      },
      touched: {
        ...prevFormState.touched,
        company: true
      }
    }))
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    setConfirmEnabled(false)
    const { values } = formState
    if (isSa && (!values.company || values.company === '')) {
      return setFormState({ ...formState, inviteSucces: false, inviteError: true, inviteMessage: t('notifications.companyError') })
    }
    let role
    if (values.userType) {
      role = `${capitalizeFirstLetter(values.userType)}${capitalizeFirstLetter(values.userRole)}`
    } else {
      role = `${capitalizeFirstLetter(isBuyerOrSupplier())}${capitalizeFirstLetter(values.userRole)}`
    }

    try {
      const companyId = isSa ? values.company : currentCompany.uuid

      // se sono superAdmin e l'invito è per un buyerAdmin la company diventa di tipo buyer
      if (isSa && companyId !== '' && role.includes('BuyerAdmin')) {
        const updatedCompany = { isBuyer: true }
        await api.putResource('companies', { path: `/${companyId}`, body: updatedCompany })
      }

      // create invitation
      const invitationBody = {
        role,
        companyId,
        email: values.email.toLowerCase()
      }
      await api.postResource('invitations', { body: invitationBody })
      // Uso questo console.log per avere il link che mi porta all'invito appena creato se sono in fase di sviluppo e ho l'ivvio delle mail disabilitato
      // console.log(`Invitation link: /invitations/${invitation.uuid}`)

      // set success message
      setFormState((prevFormState) => ({
        ...prevFormState,
        inviteError: false,
        inviteSuccess: true,
        inviteMessage: t('notifications.success'),
        values: {},
        errors: {},
        touched: {}
      }))
      setConfirmEnabled(true)
      history.push('/invitedusers')
    } catch (e) {
      setConfirmEnabled(true)
      setFormState({ ...formState, inviteSuccess: false, inviteError: true, inviteMessage: t('notifications.genericError') })
    }
  }

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]))

  useEffect(() => {
    getCompanies()
  }, [])

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      currentCompany
    }))
  }, [currentCompany])

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])
  // console.log(formState.values)
  return (
    <>
      {isSa ? <CompanyDialog setCompany={setCompany} close={handleClose} open={formState.openModal} /> : null}
      <Alert variant={formState.inviteSuccess ? 'success' : 'default'} className={!formState.inviteError && !formState.inviteSuccess ? clsx(classes.alert, classes.showAlert) : classes.alert} message={formState.inviteMessage} />
      <form
        {...rest}
        className={classes.root}
        onSubmit={handleSubmit}
      >
        <div className={classes.formGroup}>
          <TextField
            error={hasError('email')}
            helperText={hasError('email') ? formState.errors.email[0] : null}
            fullWidth
            label={t('fields.email')}
            name='email'
            onChange={(event) => handleChange(event)}
            values={formState.values.email || ''}
            variant='outlined'
          />
        </div>
        {isSa && (
          <div className={clsx(classes.formGroup, classes.compositeGroup)}>
            <FormControl className={classes.formControl}>
              <Autocomplete
                id='combo-box-demo'
                options={formState.companyList}
                onChange={(event, newValue) => {
                  handleCompanySelection(newValue)
                }}
                name='company'
                getOptionLabel={(option) => `${option.name} - ${option.vatId}`}
                getOptionSelected={(option, value) => option.uuid === value.uuid}
                style={{ width: 500 }}
                renderInput={(params) => <TextField {...params} label={t('fields.company')} variant='outlined' />}
              />
            </FormControl>
            <Box m={1}>
              <Tooltip title={t('tooltips.newCompany')}>
                <IconButton
                  color='primary'
                  onClick={() => setFormState((prevFormState) => ({
                    ...prevFormState,
                    openModal: true
                  }))}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        )}
        {
          isSa &&
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label={t('fields.userType')}
                name='userType'
                onChange={(event) => handleChange(
                  event
                )}
                select
                SelectProps={{ native: true }}
                value={formState.values.userType}
                variant='outlined'
              >
                <option
                  value=''
                />
                {
                  isAPaOperator
                    ? paOperatorUserTypeOption.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))
                    : adminUserTypeOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))
                }
              </TextField>
            </div>
        }
        <div className={classes.formGroup}>
          <TextField
            className={classes.field}
            fullWidth
            label={t('fields.userRole')}
            name='userRole'
            onChange={(event) => handleChange(
              event
            )}
            select
            SelectProps={{ native: true }}
            value={formState.values.userRole}
            variant='outlined'
            disabled={!(
              formState.values.userType &&
              adminUserRolesOptions[formState.values.userType] &&
              adminUserRolesOptions[formState.values.userType].length > 0
            ) && isSa}
          >
            <option
              value=''
            />
            {isSa
              ? formState.values.userType && (
                userRolesOptions.superAdmin.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))
              )
              : userRolesOptions[getUserType()].map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
          </TextField>
        </div>
        <Button
          className={classes.submitButton}
          color='primary'
          disabled={!formState.isValid || !confirmEnabled}
          size='large'
          type='submit'
          variant='contained'
        >
          {t('buttons.invite')}
        </Button>
      </form>
    </>
  )
}

InviteForm.propTypes = {
  className: PropTypes.string
}

export default InviteForm
