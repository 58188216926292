import api from './api'
/*
  Funzione che verifica l'esistenza di un callcenter.
  In caso di esistenza ritorna il callcenter esistente,
  altrimenti lo crea e ritorna il callcenter appena creato
*/
export default async function getExistingCallcenter (name) {
  const existentCallcenter = await api.getResource('callcenters', { params: { name } })

  if (existentCallcenter.length <= 0) {
    // create callcenter
    const callcenterBody = {
      name
    }
    const callcenter = await api.postResource('callcenters', { body: callcenterBody })
    return callcenter
  } else {
    return existentCallcenter[0]
  }
}
