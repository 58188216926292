import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import { Dialog, DialogTitle, DialogContent, Typography, DialogContentText } from '@material-ui/core'
import RegisterCompanyForm from './RegisterCompanyForm'
import RegisterCallcenterForm from './RegisterCallcenterForm'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2)
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}))

/* Componente di Dialog per la creazione di una company */
function CompanyDialog ({ open, close, setCompany, type }) {
  const classes = useStyles()

  const { t } = useTranslation('companyDialog')

  const setCompanyAndClose = (companyUuid) => {
    setCompany(companyUuid)
    close()
  }

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={close}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography component='span' variant='h3'>{type === 'callcenter' ? t('callcenterTitle') : t('companyTitle')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.root}>
        <DialogContentText>{t('text')}</DialogContentText>
        {type === 'callcenter' ? <RegisterCallcenterForm setCallcenterAndClose={setCompanyAndClose} close={close} /> : <RegisterCompanyForm setCompanyAndClose={setCompanyAndClose} close={close} />}
      </DialogContent>
    </Dialog>)
}

CompanyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setCompany: PropTypes.func.isRequired,
  type: PropTypes.string
}

CompanyDialog.defaultProps = {
  type: 'company'
}

export default CompanyDialog
