export const SET_MARKETPLACES = 'SET_MARKETPLACES'
export const SET_CURRENT_AUCTION = 'SET_CURRENT_AUCTION'
export const SET_AUCTION_SUPPLIERS = 'SET_AUCTION_SUPPLIERS'
export const SET_SELECTED_SUPPLIERS = 'SET_SELECTED_SUPPLIERS'
export const SET_PROPOSED_OFFER = 'SET_PROPOSED_OFFER'
export const CLEAR_MARKETPLACE = 'CLEAR_MARKETPLACE'

export const clearMarketplace = () => dispatch => {
  dispatch({
    type: CLEAR_MARKETPLACE
  })
}

export const setMarketplaces = (marketplaces) => (dispatch) => {
  dispatch({
    type: SET_MARKETPLACES,
    payload: marketplaces
  })
}

export const setProposedOffer = (offer) => dispatch => {
  dispatch({
    type: SET_PROPOSED_OFFER,
    payload: offer
  })
}

export const setSelectedSuppliers = (selectedSuppliers) => dispatch => {
  dispatch({
    type: SET_SELECTED_SUPPLIERS,
    payload: selectedSuppliers
  })
}

export const setAuctionSuppliers = (auctionSuppliers) => dispatch => {
  dispatch({
    type: SET_AUCTION_SUPPLIERS,
    payload: auctionSuppliers
  })
}

export const setCurrentAuction = (auction) => dispatch => {
  dispatch({
    type: SET_CURRENT_AUCTION,
    payload: auction
  })
}
