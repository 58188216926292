const languageToFlagMapping = (lang) => {
  switch (lang) {
    case 'it':
      return 'IT'
    case 'en':
      return 'GB'
    case 'en-US':
      return 'US'
    case 'es':
      return 'ES'
    case 'fr':
      return 'FR'
    case 'de':
      return 'DE'
    case 'pt':
      return 'PT'
    case 'zh':
      return 'CN'
    case 'ru':
      return 'RU'
    default:
      return 'IT'
  }
}
module.exports = {
  languageToFlagMapping
}
