import createHeaders from './createHeaders'
import axios from 'axios'
import resources from './apiResourcesConfig'

/* Util che si occupa di creare una interfaccia semplificata per effettuare chiamate http */

// funzione che si occupa di settare i parametri iniziali di ogni chiamata
const resourcesBaseOperations = (resource, { params = null, auth = null, body = null, path = null }) => {
  // creo gli headers per la chiamata
  const headers = createHeaders(params, auth)
  // url di base
  const baseUrl = resources[resource]
  // url completo
  const completeUrl = !path ? baseUrl : `${baseUrl}${path}`
  return body ? { url: completeUrl, headers, body } : { url: completeUrl, headers }
}

/*
  Tutte le funzioni di seguito ritornano i dati che restituisce la chiamata http.
  tutte le funzioni hanno le seguenti proprietà:
  - resource: Risorsa che vogliamo utilizzare (corrisponde all'url di base)
  - options: Oggetto di configurazione della chiamata

  Proprietà di options:
  - params: parametri da passare negli headers della chiamata HTTP
  - auth: parametro che sovrascrive il valore dell'header 'authorization'
  - body: body delle chiamate HTTP
  - path: continuazione del'url dopo quello di base
*/

// funzione che si occupa di fare chiamate GET
const getResource = async (resource, { ...options }) => {
  const { url, headers } = resourcesBaseOperations(resource, options)
  const resourceResponse = await axios.get(url, headers)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response.data
}

// funzione che si occupa di fare chiamate POST
const postResource = async (resource, { ...options }) => {
  const { url, headers, body } = resourcesBaseOperations(resource, options)
  const resourceResponse = await axios.post(url, body, headers)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response.data
}

// funzione che si occupa di fare chiamate PUT
const putResource = async (resource, { ...options }) => {
  const { url, headers, body } = resourcesBaseOperations(resource, options)
  if (resource === 'surveys') {
    if (headers && 'headers' in headers && 'authorization' in headers.headers) {
      delete headers.headers.authorization
    }
  }
  const resourceResponse = await axios.put(url, body, headers)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response.data
}

// funzione che si occupa di fare chiamate DELETE
const deleteResource = async (resource, { ...options }) => {
  const { url, headers } = resourcesBaseOperations(resource, options)
  const resourceResponse = await axios.delete(url, headers)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response.data
}

export default {
  getResource,
  postResource,
  deleteResource,
  putResource
}
