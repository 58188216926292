export const SET_USERS = 'SET_USERS'
export const FILTER_USERS = 'FILTER_USERS'
export const SET_RULES = 'SET_RULES'
export const SET_INVITATIONS = 'SET_INVITATIONS'
export const SET_OPERATORS = 'SET_OPERATORS'
export const CLEAR_MANAGE_USERS = 'CLEAR_MANAGE_USERS'

export const setUsers = (users) => (dispatch) => {
  dispatch({
    type: SET_USERS,
    payload: users
  })
}

export const clearManageUsers = () => (dispatch) => dispatch({
  type: CLEAR_MANAGE_USERS
})

export const setOperators = (operators) => (dispatch) => {
  dispatch({
    type: SET_OPERATORS,
    payload: operators
  })
}

export const setRules = (rules) => (dispatch) => {
  dispatch({
    type: SET_RULES,
    payload: rules
  })
}

export const setInvitations = (invitations) => (dispatch) => {
  dispatch({
    type: SET_INVITATIONS,
    payload: invitations
  })
}

export const filterUsers = (users) => (dispatch) => {
  dispatch({
    type: FILTER_USERS,
    payload: users
  })
}
