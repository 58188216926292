import React, { Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { LinearProgress } from '@material-ui/core'
import ls from 'local-storage'
import usePermissions from 'src/utils/usePermissions'
import configuration from '../../configuration'
import GoogleAnalytics from '../../components/GoogleAnalytics'
import Clarity from '../../components/Clarity'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden'
  }
}))

/* Layout per form di login e registrazione. Posso accederci solo se non sono loggato */
function Auth ({ route }) {
  const classes = useStyles()
  // prendo l'oggetto utente dal localstorage
  const user = ls.get('plsadv_user')

  // Verifico i permessi e setto utente e token nel localstorage
  usePermissions()

  return (
    <>
      {configuration.environment === 'production' ? <GoogleAnalytics /> : null}
      {configuration.environment === 'production' ? <Clarity /> : null}
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {!user ? renderRoutes(route.routes) : <Redirect to='/' />}
          </Suspense>
        </div>
      </div>
    </>
  )
}

Auth.propTypes = {
  route: PropTypes.object
}

export default Auth
