import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { makeStyles } from '@material-ui/styles'
import { Box, TextField, Button } from '@material-ui/core'
import Alert from 'src/components/Alert'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
  },
  fields: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  alert: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  showAlert: {
    opacity: 0
  }
}))

function RetryForm ({ action, showTaxId = false, showAlert, alertMessage }) {
  const classes = useStyles()
  const { t } = useTranslation('retryForm')

  const vatIdSchema = {
    vatId: {
      presence: { allowEmpty: false, message: t('vatIdSchema.presence') },
      length: { is: 11, wrongLength: t('vatIdSchema.length') }
    }
  }

  const schema = {
    vatId: {
      presence: { allowEmpty: false, message: t('schema.vatId.presence') },
      length: { is: 11, wrongLength: t('schema.vatId.length') }
    },
    taxId: {
      presence: { allowEmpty: false, message: t('schema.taxId.presence') },
      length: function (value) {
        if (value) {
          if (value.length > 11) return { is: 16, wrongLength: t('schema.taxId.length') }

          return { is: 11, wrongLength: t('schema.taxId.valid') }
        }
        return false
      }
    }
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  const [confirmEnabled, setConfirmEnabled] = useState(true)

  // funzione che aggiorna lo stato della form con i dati inseriti nei campi di input
  const handleChange = (event) => {
    event.persist()

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  // Funzione di submit della form di registrazione autonoma
  const handleSubmit = (event) => {
    setConfirmEnabled(false)
    event.preventDefault()
    if (showTaxId) {
      const { vatId, taxId } = formState.values
      action(vatId, taxId)
    } else {
      const { vatId } = formState.values
      action(vatId)
    }
    setConfirmEnabled(true)
  }

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field])

  // funzione che segnala o meno un errore su un campo di input, ogni volta che cambia valore
  useEffect(() => {
    const errors = showTaxId
      ? validate(formState.values, schema, { fullMessages: false, vatId: { type: 'number' } })
      : validate(formState.values, vatIdSchema, { fullMessages: false, vatId: { type: 'number' } })

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values, showTaxId])

  return (
    <>
      <Alert className={!showAlert ? clsx(classes.alert, classes.showAlert) : classes.alert} message={alertMessage} />
      <form onSubmit={handleSubmit}>
        <div className={classes.fields}>
          <TextField
            error={hasError('vatId')}
            fullWidth
            type='number'
            required
            helperText={hasError('vatId') ? formState.errors.vatId[0] : null}
            label={t('fields.vatId')}
            name='vatId'
            onChange={handleChange}
            value={formState.values.vatId || ''}
            variant='outlined'
          />
          {
            showTaxId && (
              <TextField
                error={hasError('taxId')}
                fullWidth
                type='text'
                required
                helperText={hasError('taxId') ? formState.errors.taxId[0] : null}
                label={t('fields.taxId')}
                name='taxId'
                onChange={handleChange}
                value={formState.values.taxId || ''}
                variant='outlined'
              />
            )
          }
        </div>
        <Box display='flex' alignItems='center'>
          <Button
            className={classes.submitButton}
            color='primary'
            disabled={!formState.isValid || !confirmEnabled}
            size='large'
            type='submit'
            variant='contained'
            endIcon={<ExitToAppIcon />}
          >
            {t('buttons.verify')}
          </Button>
        </Box>
      </form>
    </>
  )
}

RetryForm.propTypes = {
  action: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  alertMessage: PropTypes.string.isRequired
}

export default RetryForm
