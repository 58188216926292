import * as actionTypes from 'src/actions'

const initialState = {
  users: [],
  invitations: [],
  operators: [],
  rules: [],
  initialUsers: [],
  search: '',
  openFilter: false,
  selectedCustomers: [],
  page: 0,
  rowsPerPage: 10
}

const manageUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_MANAGE_USERS: {
      return initialState
    }
    case actionTypes.SET_USERS: {
      return {
        ...state,
        users: action.payload,
        initialUsers: action.payload
      }
    }
    case actionTypes.SET_RULES: {
      return {
        ...state,
        rules: action.payload
      }
    }
    case actionTypes.FILTER_USERS: {
      return {
        ...state,
        users: action.payload
      }
    }
    case actionTypes.SET_INVITATIONS: {
      return {
        ...state,
        invitations: action.payload
      }
    }
    case actionTypes.SET_OPERATORS: {
      return {
        ...state,
        operators: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default manageUsersReducer
