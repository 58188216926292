import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'

const baseTheme = {
  palette,
  typography,
  overrides
}
const themeWithoutResponsive = createMuiTheme(baseTheme)
export const theme = responsiveFontSizes(
  themeWithoutResponsive,
  {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    factor: 2
  }
)
// export const theme = themeWithoutResponsive
export const themeWithRtl = createMuiTheme({ ...baseTheme, direction: 'rtl' })
