import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const plusAdvanceRed = {
  main: '#BF1E2D',
  dark: '#A71B28',
  light: '#E9363B'
}

export default {
  primary: {
    contrastText: white,
    dark: plusAdvanceRed.dark,
    main: plusAdvanceRed.main,
    light: plusAdvanceRed.light
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A700,
    light: colors.blue.A400
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  success: {
    main: colors.green[600],
    dark: colors.green[900]
  },
  sent: {
    main: colors.lightBlue[200]
  },
  warning: {
    main: colors.amber[600],
    dark: colors.amber[900]
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    // default: '#F4F6F8',
    default: '#f5f5f5',
    paper: white
  },
  divider: colors.grey[200]
}
