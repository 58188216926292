import { combineReducers } from 'redux'
import sessionReducer from './sessionReducer'
import manageUsersReducer from './manageUsersReducer'
import marketplaceReducer from './marketplaceReducer'

// punto di accesso per tutti i reducers presenti, vengono uniti tramite la funzione combineReducers
const rootReducer = combineReducers({
  session: sessionReducer,
  manageUsers: manageUsersReducer,
  marketplace: marketplaceReducer
})

export default rootReducer
