import { useEffect } from 'react'
import ls from 'local-storage'
import { useSelector, useDispatch } from 'react-redux'
import { login, logout, setCompany, setCompanies, setMarketplaces, setHeadCompanies, setHeadCompany } from 'src/actions'
import api from './api'
import { useHistory } from 'react-router'

// Funzione che prende una company in base al suo uuid
async function getCompany (companyId) {
  try {
    const company = await api.getResource('companies', { path: `/${companyId}` })
    return company
  } catch (e) {
    return {}
  }
}

// Funzione che aggiunge un le info di un operatore ad una assrgnazione del callcenter
const enrichAssignments = async (assignments) => {
  return Promise.all(assignments.map(async assignment => {
    try {
      const user = await api.getResource('users', { path: `/${assignment.userId}` })
      const assignmentObj = { user, ...assignment }
      return assignmentObj
    } catch (e) {
      return assignment
    }
  }))
}

/*
  Funzione che prende un callcenter partendo dal suo uuid
  aggiungendo le informazioni mancanti alle assegnazioni
*/
const getCallcenterById = async (callcenterId) => {
  try {
    const callcenter = await api.getResource('callcenters', { path: `/${callcenterId}` })
    const richAssignments = await enrichAssignments(callcenter.operatorAssignments)
    callcenter.operatorAssignments = richAssignments
    return callcenter
  } catch (e) {
    return {}
  }
}

// Funzione che ritorna tutte le aziende o callcenters di cui l'utente loggato fa parte in base alle aclrules
const getCompanies = async (user) => {
  return Promise.all(
    user.role.map(async (role) => {
      const myCompanies = await role.role.includes('CallCenter') ? getCallcenterById(role.companyId) : getCompany(role.companyId)
      return myCompanies
    }))
}

// Funzione che ritorna tutte le aziende madre di cui l'utente loggato fa parte
const getHeadCompanies = async (user) => {
  try {
    const headCompanies = await api.getResource('headCompanies', { path: `?administrators=${user.uuid}` })
    // console.log(headCompanies)
    return headCompanies
  } catch (e) {
    return []
  }
}

// // funzione che prende tutti i callcenters
// const getCallcenters = async () => {
//   try {
//     const callcenters = await api.getResource('callcenters')
//     return callcenters
//   } catch (e) {
//     return []
//   }
// }

// funzione che setta nello stato globale le companies e la compagnia con la quale sto visualizzando la dashboard
async function manageCompanies (user, dispatch) {
  const headCompanies = await getHeadCompanies(user)
  const companies = await getCompanies(user)
  const currentCompany = companies.length > 0 ? companies[0] : {}
  const currentHeadCompany = headCompanies.length > 0 ? headCompanies[0] : {}
  dispatch(setCompanies(companies))
  dispatch(setHeadCompanies(headCompanies))
  dispatch(setCompany(currentCompany))
  dispatch(setHeadCompany(currentHeadCompany))
}

// funzione che setta nello stato globale i marketplaces per l'azienda fornitrice con la quale sto visualizzando la dashboard
async function manageMarketplaces (companyId, dispatch) {
  try {
    const marketplaces = await api.getResource('companies', { path: `/${companyId}/marketplaces/find/bySupplier` })
    dispatch(setMarketplaces(marketplaces))
  } catch (e) {
    dispatch(setMarketplaces([]))
  }
}

// funzione che setta nello stato globale i marketplaces per l'azienda buyer con la quale sto visualizzando la dashboard
async function manageBuyerMarketplaces (companyId, dispatch) {
  try {
    const marketplaces = await api.getResource('companies', {
      path: `/${companyId}/marketplaces`,
      params: {
        filter: JSON.stringify({ buyerId: companyId }),
        fields: 'uuid,name,isDefault,uncertainDate,status,buyerId,currentAuction,settings,companyCode,buyerVAT,taxId,currency,reminderHours,markupMinDiscount,addendum'
      }
    })
    dispatch(setMarketplaces(marketplaces))
  } catch (e) {
    dispatch(setMarketplaces([]))
  }
}

// // funzione che setta nello stato globale i callcenters
// async function manageCallcenters (dispatch) {
//   const callcenters = await getCallcenters()
//   dispatch(setCallcenters(callcenters))
// }
const isDiscountPreferenceUrl = (requestedLocation) => {
  return requestedLocation && requestedLocation instanceof String && requestedLocation.includes('discountPreferences')
}

const isCheckoutUrl = (requestedLocation) => {
  return requestedLocation && requestedLocation.includes('advancedPayments')
}
function usePermissions () {
  const dispatch = useDispatch()
  const history = useHistory()
  const session = useSelector((state) => state.session)
  const loggedIn = session.loggedIn
  const requestedLocation = history.location.pathname
  useEffect(() => {
    async function setPermissions () {
      const token = ls.get('plsadv_token')
      const user = ls.get('plsadv_user')
      if (token && !loggedIn) {
        try {
          // Prendo tutti i miei permessi(aclrules)
          const permissions = await api.getResource('users', { path: '/self/aclrules' })
          const userDetails = await api.getResource('users', { path: `/${user.uuid}` })
          const { productTours, jobTitle, mfaActivated } = userDetails
          user.role = permissions
          user.jobTitle = jobTitle
          user.mfaActivated = mfaActivated
          user.marketplaceTourDone = productTours.marketplaceList
          user.marketplaceDetailsTourDone = productTours.marketplaceDetails
          // setto globalmente l'utente e la variabile loggedIn a true
          dispatch(login(user))
          manageCompanies(user, dispatch)
          // manageCallcenters(dispatch)
          if (user.role && user.role.length > 0 && user.role[0].role.includes('Supplier')) {
            const companyId = user.role.length > 0 ? user.role[0].companyId : ''
            manageMarketplaces(companyId, dispatch)
          }
          if (user.role && user.role.length > 0 && user.role[0].role.includes('Buyer')) {
            const companyId = user.role.length > 0 ? user.role[0].companyId : ''
            manageBuyerMarketplaces(companyId, dispatch)
          }
          if (user.role && user.role.length > 0 && !user.role[0].role.includes('CallCenter')) {
            const companyId = user.role.length > 0 ? user.role[0].companyId : ''
            if (companyId !== '') {
              const company = await getCompany(companyId)
              dispatch(setCompany(company))
            }
          }
        } catch (e) {
          if (!isDiscountPreferenceUrl(requestedLocation) || !isCheckoutUrl(requestedLocation)) {
            dispatch(logout())
            ls.clear()
            // console.log('Discount preference')
            history.push(`/auth/login?requestedUrl=${requestedLocation}`)
          }
        }
      } else if (!token && loggedIn && !isDiscountPreferenceUrl(requestedLocation)) {
        dispatch(logout())
        ls.clear()
        history.push(`/auth/login?requestedUrl=${requestedLocation}`)
      } else if (!token && !loggedIn && user && !isDiscountPreferenceUrl(requestedLocation)) {
        dispatch(logout())
        ls.clear()
        history.push(`/auth/login?requestedUrl=${requestedLocation}`)
      }
    }
    setPermissions()
    // eslint-disable-next-line
  }, [loggedIn])
}

export default usePermissions
