import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// OPTIONAL
// import Backend from 'i18next-xhr-backend' // used to have a pre-render server side with multiple files
// https://github.com/i18next/i18next-xhr-backend
import LanguageDetector from 'i18next-browser-languagedetector'
// https://github.com/i18next/i18next-browser-languageDetector
import italianCommon from './translations/it/common.json'
// eslint-disable-next-line
import englishCommon from './translations/en/common.json'
import spanishCommon from './translations/es/common.json'
import frenchCommon from './translations/fr/common.json'
import germanCommon from './translations/de/common.json'
import portogueseCommon from './translations/pt/common.json'
import chineseCommon from './translations/zh/common.json'
import russianCommon from './translations/ru/common.json'

// Some component functions
// https://www.i18next.com/overview/api#addresourcebundle

// variaible con le opzioni del LanguageDetector
const languageDetectorOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator', 'cookie', 'querystring', 'htmlTag', 'path', 'subdomain'],
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  // cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement

  // only detect languages that are in the whitelist
  // checkWhitelist: true
}

// variabile con le risorse
const resources = {
  it: italianCommon,
  'en-US': englishCommon,
  en: englishCommon,
  es: spanishCommon,
  fr: frenchCommon,
  de: germanCommon,
  pt: portogueseCommon,
  zh: chineseCommon,
  ru: russianCommon
}

// create component istance
i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'it',
    // lng: 'it', // initialize i18next with language to use
    debug: process.env.REACT_APP_ENV === 'test',
    // nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
    detection: languageDetectorOptions, // per passare opzioni del languageDeterctor
    resources,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }, (err, t) => {
    if (err) return console.error('something went wrong loading i18next, err => ', err)
    t('key') // -> same as i18next.t
  })

export default i18n
