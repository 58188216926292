/* eslint-disable react/no-danger */
import React from 'react'
import { Helmet } from 'react-helmet'
import configuration from 'src/configuration'
import { useHistory } from 'react-router'
import ignorePath from '../utils/pathToIgnoreForGA'

/* const gtmScript = `(function(w,d,s,l,i){w[l] = w[l] || []{'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-NZ6DLML');` */

function GoogleAnalytics () {
  const history = useHistory()
  const requestedLocation = history.location.pathname
  return (
    requestedLocation && !ignorePath(requestedLocation) &&
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${configuration.gtmId}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${configuration.gtmId}');
        `}
      </script>
    </Helmet>
  )
}

export default GoogleAnalytics
