import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Button
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'
import { languageToFlagMapping } from 'src/utils/flagUtils'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LanguagePopover from '../LanguagePopover'

// Definizione degli stili per il componente
const useStyles = makeStyles((theme) => ({
  whiteIcon: {
    color: 'white'
  },
  greyIcon: {
    color: '#546e7a'
  },
  arrowIcon: {
    marginRight: 0
  },
  flagIcon: {
    marginLeft: '2px'
  }
}))

/* Componente responsabile della visualizzazione del menu sulla TopBar */
function ChangeLanguageButton ({ useWhite = true }) {
  const classes = useStyles()
  const [langOpen, setLangOpen] = useState(false)
  const languageRef = useRef(null)

  const { i18n } = useTranslation('profilePopover')
  const handleLanguageClose = () => {
    setLangOpen(false)
  }
  return (

    <>
      <Button
        // variant='outlined'
        classes={{
          startIcon: classes.arrowIcon,
          endIcon: classes.flagIcon
        }}
        endIcon={
          <ReactCountryFlag
            svg
            countryCode={languageToFlagMapping(i18n.language)}
            style={{
              width: '1.3em',
              height: '',
              border: '0.5px solid white'
            }}
          />
        }
        // variant='outlined'
        onClick={() => setLangOpen(!langOpen)}
        ref={languageRef}
        startIcon={<KeyboardArrowDownIcon className={useWhite ? classes.whiteIcon : classes.greyIcon} color='action' />}
      />
      <LanguagePopover
        anchorEl={languageRef.current}
        onClose={handleLanguageClose}
        open={langOpen}
      />
    </>
  )
}

export default ChangeLanguageButton
