import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Card, CardContent, CardHeader } from '@material-ui/core'

import Page from 'src/components/Page'
import Header from './Header'
import InviteForm from './InviteForm'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(5)
  },
  cardHeader: {
    textTransform: 'uppercase'
  }
}))

/* Componente della view della creazione ed invio dell'invito */
function InviteUserView () {
  const classes = useStyles()

  const { t } = useTranslation('inviteUserView')

  return (
    <Page
      className={classes.root}
      title={t('title')}
    >
      <Container maxWidth='xl'>
        <Header />
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} title={t('cardTitle')} />
          <CardContent>
            <InviteForm />
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default InviteUserView
