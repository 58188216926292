import ls from 'local-storage'

// Funzione che si occupa di creare l'header di autorizzazione e popolarlo con il token presente nel localStorage
export default function createHeaders (params = null, overrideAuthToken = null) {
  const token = ls.get('plsadv_token')

  const headers = {
    headers: {}
  }

  if (overrideAuthToken || token) {
    headers.headers.authorization = overrideAuthToken || token
  }

  if (params) {
    headers.params = params
  }

  return headers
}
